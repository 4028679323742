export default async function usePreferences(name, value = null) {
    const appStore = useAppStore();
    let result = ref({});

    try {
        if (value === null) {
            const { data, error, refresh, pending } = await useAsyncData(
                'mePreferences',
                () => $fetch('/api/v1/me/preferences'),
                {
                    server: false,
                    immediate: true,
                    transform: (e) => {
                        appStore.set('me.preferences', e);
                        return e;
                    },
                },
            );

            if (error.value) {
                console.error('mePreferencesError', error.value);
                useError(error.value, "Can't reload user preferences");
            }

            // // GET-Anfrage, wenn kein `value` übergeben wird
            // const { data, error } = await useAsyncData(
            //     'userPreferences',
            //     () => $fetch('/api/v1/me/preferences/')
            // )

            // if (error.value) {
            //     useError(error.value, 'Can\'t load user preferences')
            // }
            return data.value?.[`${name}`] || result;
        } else {
            // PATCH-Anfrage, um eine Einstellung zu aktualisieren
            if (typeof name !== 'string' || value === undefined) {
                // useError("Invalid data", "Can't set user preference");
                useError(null, "Can't Invalid user preference");
                return;
            }

            const {
                data,
                error
            } = await useFetch('/api/v1/me/preferences/', {
                method: 'PATCH',
                server: false,
                immediate: true,
                body: {
                    name: name,
                    value: value,
                },
                onResponse({ request, response, options }) {
                    console.log(response._data);
                    result.value = response._data || {};
                },
                onResponseError({ request, response, options }) {
                    useError(response, "Can't update user preference");
                },
            });

            if (error.value) {
                useError(error.value, 'Something is wrong while set user preferences');
            }

            // return result.value?.[`${name}`] || result;
            return data.value // ?.[`${name}`] || result;
        }
    } catch (err) {
        useError(null, "Can't use user preferences");
    }
}